/* eslint-disable @next/next/no-html-link-for-pages */
import {
  BottomGraphic,
  FadeIn,
  Footer,
  Header,
  Hero,
  Product,
  WidgetExample,
} from "../components"
import { classNames } from "../utilities"
import {
  ChatAltIcon,
  CheckCircleIcon,
  ClipboardCheckIcon,
  CodeIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  DownloadIcon,
  UserGroupIcon,
} from "@heroicons/react/outline"
import { chunk } from "lodash"
import type { NextPage } from "next"
import Image from "next/image"
import Link from "next/link"
import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

export const smallBusinessIndustries = [
  "Wholesalers",
  "Manufacturers",
  "Retailers",
  "Construction Companies",
  "Restaurants",
  "Bars",
  "Laundromats",
  "Lawyers",
  "Real Estate Agencies",
  "Auto Services",
  "Architects",
  "Dentists",
  "Doctors",
  "Hotels",
  "Farmers",
  "And more",
]
export const startupIndustries = [
  "SaaS",
  "Fintech",
  "Insurtech",
  "Payment",
  "AI",
  "Machine Learning",
  "Internet of Things (IOT)",
  "VR / AR",
  "Blockchain",
  "Crypto",
  "Cybersecurity",
  "App Development",
  "Healthtech",
  "Edtech",
  "And more",
]
export const carrierNames = [
  "amtrust.svg",
  "chubb.svg",
  "cna.svg",
  "coalition.svg",
  "coterie.svg",
  "cowbell.svg",
  // "next.svg",
  "rt-specialty.svg",
  "employers.svg",
  "foxquilt.svg",
  "liberty-mutual.svg",
  "nationwide.svg",
  "travelers.svg",
  "markel.svg",
  "core-specialty.svg",
  "arch.png",
  "hiscox.svg",
  "atbay.png",
]
export const IndustryCarouselGroups = chunk(smallBusinessIndustries, 6)
export const StartupIndustryCarouselGroups = chunk(startupIndustries, 6)
export const CarrierCarouselGroups = chunk(carrierNames, 7)
const embedSteps = [
  {
    title: "Install the Coverdash component from NPM",
    icon: DownloadIcon,
  },
  {
    title: "Add the component with a single line of code",
    icon: CodeIcon,
  },
  {
    title:
      "Provide affordable insurance to your customers by start of business tomorrow",
    icon: UserGroupIcon,
  },
  {
    title: "Unlock ancillary insurance revenue stream",
    icon: CurrencyDollarIcon,
  },
]
const coverageItems = [
  {
    description:
      "Highly scalable, ancillary revenue stream with no additional cost",
    icon: "/img/partner1.svg",
  },
  {
    description: "Full suite of simplified and automated integration options",
    icon: "/img/partner2.svg",
  },
  {
    description: "Reduce liability exposure and increase customer service",
    icon: "/img/partner3.svg",
  },
  {
    description: "Generate Certificate of Insurance (COI) instantaneously",
    icon: "/img/partner4.svg",
  },
]

const Home: NextPage = () => {
  return (
    <>
      {/* <GradientBg /> */}
      <Header />
      <Hero />

      {/* 3 simple steps to instant coverage */}
      <div className="bg-[#fbfbfb] border-y border-slate-200 py-16">
        <div className="container mx-auto px-8 xl:px-32">
          <FadeIn>
            <h2 className="text-5xl text-alt font-extrabold my-7 text-center">
              3 Simple steps to <span className="text-primary">instant</span>{" "}
              coverage
            </h2>

            <div className="text-center text-xl text-gray-500 mt-4">
              Fast, easy, and reliable coverage that reaches businesses where
              they are, when they need it
            </div>
          </FadeIn>

          <div className="md:grid grid-cols-3 gap-10 mt-16">
            <FadeIn>
              <div className="grid grid-cols-4 md:grid-cols-1 gap-3 md:gap-0 md:justify-items-center md:text-center mb-12">
                <Image
                  src="/img/step1.svg"
                  alt="Illustration of lady with tablet"
                  width={325}
                  height={325}
                  aria-hidden="true"
                />
                <div
                  className="lg:mt-5 grid col-span-3 items-center text-2xl font-semibold text-gray-600"
                  style={{ gridTemplateColumns: "max-content 1fr" }}
                >
                  <div className="text-6xl font-bold text-gray-300 mr-4 sm:mr-2">
                    1
                  </div>
                  Tell us what you do
                </div>
              </div>
            </FadeIn>

            <FadeIn delay={0.2}>
              <div className="grid grid-cols-4 md:grid-cols-1 gap-3 md:gap-0 md:justify-items-center md:text-center mb-12">
                <Image
                  src="/img/step2.svg"
                  alt="Illustration of man pointing at tablet"
                  width={325}
                  height={325}
                  aria-hidden="true"
                />
                <div
                  className="lg:mt-5 grid col-span-3 items-center text-2xl font-semibold text-gray-600"
                  style={{ gridTemplateColumns: "max-content 1fr" }}
                >
                  <div className="text-6xl font-bold text-gray-300 mr-4 sm:mr-2">
                    2
                  </div>
                  Select your policy
                </div>
              </div>
            </FadeIn>

            <FadeIn delay={0.4}>
              <div className="grid grid-cols-4 md:grid-cols-1 gap-3 md:gap-0 md:justify-items-center md:text-center mb-12">
                <Image
                  src="/img/step3.svg"
                  alt="Illustration of many holding phone"
                  width={325}
                  height={325}
                  aria-hidden="true"
                />
                <div
                  className="lg:mt-5 grid col-span-3 items-center text-2xl font-semibold text-gray-600"
                  style={{ gridTemplateColumns: "max-content 1fr" }}
                >
                  <div className="text-6xl font-bold text-gray-300 mr-4 sm:mr-2">
                    3
                  </div>
                  Get instant coverage
                </div>
              </div>
            </FadeIn>
          </div>
        </div>
      </div>

      {/* Who we insure */}
      <div className="bg-white py-16 px-12">
        <div className="mx-auto container">
          <FadeIn>
            <h2 className="text-5xl text-alt font-extrabold my-7 text-center ">
              Who we <span className="text-primary">insure</span>
            </h2>
            <div className="text-center sm:w-1/2 mx-auto mt-4 text-lg text-gray-500">
              At Coverdash, we understand how to protect your business and can
              provide – tailored, low-cost and hassle-free insurance coverage
            </div>
          </FadeIn>
        </div>

        {/* SMALL BUSINESS OWNER */}
        <div className="max-w-5xl mx-auto mt-16">
          <div className="grid grid-cols-1 sm:grid-cols-3 grid-flow-row auto-rows-min gap-4 md:gap-8 lg:gap-12">
            <div className="aspect-square text-center row-span-1 lg:row-span-2">
              <FadeIn>
                <img
                  className="w-32 h-32 md:w-full md:h-full object-cover shadow-lg rounded-lg mx-auto sm:mx-0"
                  src="/img/insure3.jpg"
                  alt="Illustration of woman in front of business"
                  aria-hidden="true"
                />
              </FadeIn>
            </div>

            <div className="col-span-2 row-span-2 h-full flex flex-col justify-around order-2 md:order-1">
              <FadeIn delay={0.2}>
                <p className="text-2xl font-medium text-primary">
                  Small business owner
                </p>
                <p className="text-lg mt-2 max-w-lg">
                  Managing your own business means you have enough on your
                  plate.
                </p>
                <div className="mt-8 sm:flex">
                  <a href="/quote" className="btn-primary w-full sm:w-auto">
                    Get started
                  </a>

                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <Link legacyBehavior href="/small-business">
                      <a className="btn-secondary w-full sm:w-auto">
                        Learn more
                      </a>
                    </Link>
                  </div>
                </div>
                <Carousel
                  className="mt-4"
                  showStatus={false}
                  showArrows={false}
                  showIndicators
                  infiniteLoop
                  autoPlay
                  renderIndicator={(
                    clickHandler: (
                      e: React.MouseEvent | React.KeyboardEvent
                    ) => void,
                    isSelected: boolean
                  ) => (
                    <button
                      onClick={clickHandler}
                      type="button"
                      className={classNames(
                        isSelected ? "bg-primary" : "bg-white",
                        "mx-1 w-2 h-2 ring-1 ring-primary rounded-full"
                      )}
                    />
                  )}
                >
                  {IndustryCarouselGroups.map((group, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-2 sm:grid-cols-3 gap-2 mt-4 pb-10"
                    >
                      {group.map((item, idx) => (
                        <div
                          key={idx}
                          className="flex items-center text-left space-x-2 text-sm font-medium text-gray-500"
                        >
                          <CheckCircleIcon className="h-4 w-5 text-primary flex-shrink-0" />
                          <span className="flex-1">{item}</span>
                        </div>
                      ))}
                    </div>
                  ))}
                </Carousel>
              </FadeIn>
            </div>
          </div>
        </div>

        {/* STARTUP */}
        <div className="max-w-5xl mx-auto mt-16">
          <div className="grid grid-cols-1 sm:grid-cols-3 grid-flow-row auto-rows-min gap-4 md:gap-8 lg:gap-12">
            {/* MOBILE IMAGE PLACEMENT */}
            <div className="aspect-square text-center row-span-1 lg:row-span-2 sm:hidden">
              <FadeIn>
                <img
                  className="w-32 h-32 md:w-full md:h-full object-cover shadow-lg rounded-lg mx-auto sm:mx-0"
                  src="/img/startup-owner.jpg"
                  alt="Illustration of woman in front of business"
                  aria-hidden="true"
                />
              </FadeIn>
            </div>

            <div className="col-span-2 row-span-2 h-full flex flex-col ">
              <FadeIn delay={0.2}>
                <p className="text-2xl font-medium text-primary">Startup</p>
                <p className="text-lg mt-2 max-w-lg">
                  Don't let unnecessary business risks make your founder journey
                  bumpier than it needs to be.
                </p>
                <div className="mt-8 sm:flex">
                  <a href="/quote" className="btn-primary w-full sm:w-auto">
                    Get started
                  </a>

                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <Link legacyBehavior href="/startup">
                      <a className="btn-secondary w-full sm:w-auto">
                        Learn more
                      </a>
                    </Link>
                  </div>
                </div>
                <Carousel
                  className="mt-4"
                  showStatus={false}
                  showArrows={false}
                  showIndicators
                  infiniteLoop
                  autoPlay
                  renderIndicator={(
                    clickHandler: (
                      e: React.MouseEvent | React.KeyboardEvent
                    ) => void,
                    isSelected: boolean
                  ) => (
                    <button
                      onClick={clickHandler}
                      type="button"
                      className={classNames(
                        isSelected ? "bg-primary" : "bg-white",
                        "mx-1 w-2 h-2 ring-1 ring-primary rounded-full"
                      )}
                    />
                  )}
                >
                  {StartupIndustryCarouselGroups.map((group, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-2 sm:grid-cols-3 gap-2 mt-4 pb-10"
                    >
                      {group.map((item, idx) => (
                        <div
                          key={idx}
                          className="flex items-center text-left space-x-2 text-sm font-medium text-gray-500"
                        >
                          <CheckCircleIcon className="h-4 w-5 text-primary flex-shrink-0" />
                          <span className="flex-1">{item}</span>
                        </div>
                      ))}
                    </div>
                  ))}
                </Carousel>
              </FadeIn>
            </div>

            {/* DESKTOP IMAGE PLACEMENT */}
            <div className="aspect-square text-center row-span-1 lg:row-span-2 hidden sm:block">
              <FadeIn>
                <img
                  className="w-32 h-32 md:w-full md:h-full object-cover shadow-lg rounded-lg mx-auto sm:mx-0"
                  src="/img/startup-owner.jpg"
                  alt="Illustration of woman in front of business"
                  aria-hidden="true"
                />
              </FadeIn>
            </div>
          </div>
        </div>

        {/* E-COMMERCE MERCHANT */}
        <div className="max-w-5xl mx-auto mt-16">
          <div className="grid grid-cols-1 sm:grid-cols-3 grid-flow-row auto-rows-min gap-4 md:gap-8 lg:gap-12">
            <div className="aspect-square text-center row-span-1 lg:row-span-2">
              <FadeIn delay={0.2}>
                <img
                  className="w-32 h-32 md:w-full md:h-full object-cover shadow-lg rounded-lg mx-auto sm:mx-0"
                  src="/img/insure2.jpg"
                  alt="Illustration of man holding box"
                  aria-hidden="true"
                />
              </FadeIn>
            </div>
            <div className="col-span-2 row-span-2 h-full flex flex-col">
              <FadeIn>
                <p className="text-2xl font-medium text-primary">
                  E-Commerce merchant
                </p>
                <p className="text-lg mt-2 max-w-lg">
                  Selling online is hard enough without having to think about
                  what else can go wrong.
                </p>
                <div className="mt-8 sm:flex">
                  <a href="/quote" className="btn-primary w-full sm:w-auto">
                    Get started
                  </a>

                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <Link legacyBehavior href="/e-commerce">
                      <a className="btn-secondary w-full sm:w-auto">
                        Learn more
                      </a>
                    </Link>
                  </div>
                </div>
                <div className="grid grid-cols-3 md:grid-flow-col auto-cols-fit gap-4 mt-6 max-w-xl w-auto">
                  <div className="flex justify-center items-center">
                    <img
                      className="max-h-8"
                      src="/img/logos/amazon.svg"
                      alt="Amazon Logo"
                    />
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      className="max-h-8"
                      src="/img/logos/ebay.svg"
                      alt="Ebay Logo"
                    />
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      className="max-h-8"
                      src="/img/logos/etsy.svg"
                      alt="Etsy Logo"
                    />
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      className="max-h-8"
                      src="/img/logos/walmart.svg"
                      alt="Walmart Logo"
                    />
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      className="max-h-8"
                      src="/img/logos/target.svg"
                      alt="Target Logo"
                    />
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      className="max-h-8"
                      src="/img/logos/shopify.svg"
                      alt="Shopify Logo"
                    />
                  </div>
                </div>
              </FadeIn>
            </div>
          </div>
        </div>

        {/* FREELANCER */}
        <div className="max-w-5xl mx-auto mt-16">
          <div className="grid grid-cols-1 sm:grid-cols-3 grid-flow-row auto-rows-min gap-4 md:gap-8 lg:gap-12">
            <div className="col-span-2 row-span-2 h-full flex flex-col justify-around order-2 md:order-1">
              <FadeIn>
                <p className="text-2xl font-medium text-primary">Freelancer</p>
                <p className="text-lg mt-2 max-w-lg">
                  Freelancing is all about keeping things simple and focusing on
                  what you do best.
                </p>
                <div className="mt-8 sm:flex">
                  <a href="/quote" className="btn-primary w-full sm:w-auto">
                    Get started
                  </a>

                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <Link legacyBehavior href="/freelancer">
                      <a className="btn-secondary w-full sm:w-auto">
                        Learn more
                      </a>
                    </Link>
                  </div>
                </div>
                <div className="grid grid-cols-3 md:grid-cols-none md:grid-flow-col auto-cols-fit gap-4 mt-6 max-w-xl w-auto">
                  <div className="flex justify-center items-center">
                    <img
                      className="max-h-10"
                      src="/img/logos/fiverr.svg"
                      alt="Fiverr"
                    />
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      className="max-h-10"
                      src="/img/logos/upwork.svg"
                      alt="Upwork"
                    />
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      className="max-h-10"
                      src="/img/logos/toptal.svg"
                      alt="Toptal"
                    />
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      className="max-h-10"
                      src="/img/logos/99designs.svg"
                      alt="99 Designs"
                    />
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      className="max-h-10"
                      src="/img/logos/guru.svg"
                      alt="Guru"
                    />
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      className="max-h-10"
                      src="/img/logos/freelancer.svg"
                      alt="Freelancer"
                    />
                  </div>
                </div>
              </FadeIn>
            </div>
            <div className="aspect-square text-center row-span-1 lg:row-span-2 order-1 md:order-2">
              <FadeIn delay={0.2}>
                <img
                  className="w-32 h-32 md:w-full md:h-full object-cover shadow-lg rounded-lg mx-auto sm:mx-0"
                  src="/img/insure1.jpg"
                  alt="Illustration of man holding box"
                  aria-hidden="true"
                />
              </FadeIn>
            </div>
          </div>
        </div>
      </div>

      {/* Explore our Business Insurance Products */}
      <div className="bg-[#fbfbfb] border-y border-slate-200 py-16">
        <div className="container mx-auto px-8 md:px-0">
          <FadeIn>
            <h2 className="text-5xl mx-auto max-w-5xl text-alt font-extrabold my-7 text-center">
              Explore our <span className="text-primary">business</span>{" "}
              insurance products
            </h2>
          </FadeIn>

          <div className="sm:px-0 px-6 grid grid-cols-1 sm:grid-cols-2 md:flex flex-wrap gap-x-5 gap-y-4 md:gap-y-8 justify-center mt-6 max-w-5xl mx-auto">
            <FadeIn>
              <Product
                link="/general-liability"
                title="General Liability"
                image="/img/sb1.jpg"
              />
            </FadeIn>

            <FadeIn delay={0.2}>
              <Product
                link="/business-policy"
                title="Business Owner's Policy"
                image="/img/sb5.jpg"
              />
            </FadeIn>

            <FadeIn delay={0.4}>
              <Product
                link="/workers-compensation"
                title="Workers' Compensation"
                image="/img/sb4.jpg"
              />
            </FadeIn>

            <FadeIn delay={0.5}>
              <Product link="/cyber" title="Cyber" image="/img/sb3.jpg" />
            </FadeIn>
            <FadeIn delay={0.3}>
              <Product
                link="/professional-liability"
                title="Professional Liability"
                image="/img/sb2.jpg"
              />
            </FadeIn>
            <FadeIn delay={0.5}>
              <Product
                link="/management-liability"
                title="Management Liability"
                image="/img/mgl.jpg"
              />
            </FadeIn>
          </div>
        </div>
      </div>

      {/* App screenshot */}
      <div className="bg-slate-100 border-t border-slate-200 py-16 relative overflow-hidden">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-4xl lg:px-8">
          <div>
            <h2 className="mt-2 text-3xl tracking-tight text-alt font-extrabold sm:text-5xl">
              <span className="text-primary">Simplified</span> insurance
              experience
            </h2>
            <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
              Instant access to your own personalized customer dashboard to
              manage, monitor and control your insurance coverages
            </p>
          </div>
        </div>

        <div className="mt-24 max-w-4xl mx-auto">
          <img
            className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
            src="/img/dashboard-home.png"
            alt=""
          />
        </div>

        <div className="mt-32">
          <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
            <div className="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
              <FadeIn delay={0.2}>
                <div>
                  <span className="flex h-12 w-12 items-center justify-center rounded-md bg-primary">
                    <DocumentTextIcon
                      className="h-8 w-8 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl text-alt font-extrabold">
                    Easy access to policy information
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    No calling, emailing, or waiting on hold - access your
                    specific policy information from anywhere at any time. We
                    make your policy accessible and easy to understand so you
                    can get back to running your business.
                  </p>
                  <div className="mt-6">
                    <a href="/quote" className="btn-primary px-4 py-2">
                      Get started
                    </a>
                  </div>
                </div>
              </FadeIn>
            </div>
            <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
              <FadeIn style={{ height: "100%" }}>
                <div className="px-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="/img/dashboard-policy.jpg"
                    alt="Coverdash user dashboard certificate page"
                  />
                </div>
              </FadeIn>
            </div>
          </div>
        </div>

        <div className="mt-24">
          <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
            <div className="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
              <FadeIn>
                <div>
                  <span className="flex h-12 w-12 items-center justify-center rounded-md bg-primary">
                    <ClipboardCheckIcon
                      className="h-8 w-8 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold text-alt">
                    Create a certificate of insurance (“COI”) in seconds
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Certificates of insurance protect small business owners and
                    their partners before they begin working together. Has a
                    client or business partner requested proof of business
                    insurance coverage? You can instantaneously generate
                    certificates of insurance for your business on your own or
                    with the assistance of a Coverdash agent.
                  </p>
                  <div className="mt-6">
                    <a href="/quote" className="btn-primary px-4 py-2">
                      Get started
                    </a>
                  </div>
                </div>
              </FadeIn>
              <div className="mt-8 pt-6" />
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <FadeIn delay={0.2} style={{ height: "100%" }}>
                <div className="px-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="/img/dashboard-cert.jpg"
                    alt="Coverdash user dashboard policy detail page"
                  />
                </div>
              </FadeIn>
            </div>
          </div>
        </div>

        <div className="mt-24">
          <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
            <div className="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
              <FadeIn delay={0.2}>
                <div>
                  <span className="flex h-12 w-12 items-center justify-center rounded-md bg-primary">
                    <ChatAltIcon
                      className="h-8 w-8 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl text-alt font-extrabold">
                    Want to chat? We're here to help
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Coverdash's team of insurance experts are standing by to
                    provide one-on-one support. Reach out with any questions,
                    concerns, or requests and let us do what we do best.
                  </p>
                  <div className="mt-6">
                    <a href="/quote" className="btn-primary px-4 py-2">
                      Get started
                    </a>
                  </div>
                </div>
              </FadeIn>
            </div>
            <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
              <FadeIn style={{ height: "100%" }}>
                <div className="px-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="/img/dashboard-chat.jpg"
                    alt="Coverdash user dashboard support"
                  />
                </div>
              </FadeIn>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-slate-900 pt-16 pb-8">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h2 className="text-center text-3xl font-bold text-slate-100">
            Our carrier partners
          </h2>
          <Carousel
            className="mt-12"
            showStatus={false}
            showArrows={false}
            showIndicators={false}
            stopOnHover={false}
            interval={5000}
            transitionTime={1500}
            infiniteLoop
            autoPlay
          >
            {CarrierCarouselGroups.map((group, index) => (
              <div
                key={index}
                className="grid grid-cols-2 md:grid-cols-7 gap-8 px-4"
              >
                {group.map((item, idx) => (
                  <div
                    key={idx}
                    className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
                  >
                    {item === "atbay.png" ? (
                      <img
                        style={{
                          width: "100px",
                          marginLeft: "-3rem",
                        }}
                        className={classNames("h-12")}
                        src={`/img/carriers/${item}`}
                        alt={`${item
                          .replace(".png", "")
                          .replace(".svg", "")} logo`}
                      />
                    ) : (
                      <img
                        className={classNames("h-12")}
                        src={`/img/carriers/${item}`}
                        alt={`${item
                          .replace(".png", "")
                          .replace(".svg", "")} logo`}
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
          </Carousel>
        </div>
      </div>

      {/* Become a partner */}
      <div className="bg-white border-slate-200 px-8 py-16">
        <div className="mx-auto container">
          <FadeIn>
            <div className="container mx-auto">
              <h2 className="text-5xl text-alt font-extrabold my-7 text-center">
                Become an embedded <span className="text-primary">partner</span>
              </h2>
              <p className="text-center text-xl text-gray-500 mt-4 my-6">
                Embed our insurance experience into your platform
              </p>
            </div>
          </FadeIn>
          <div className="mt-12 lg:mt-24 lg:grid lg:grid-cols-5 lg:items-center lg:gap-16 max-w-3xl lg:max-w-none mx-auto">
            <div className="col-span-2">
              <FadeIn>
                <h3 className="text-2xl font-extrabold text-alt sm:text-3xl">
                  Embed with a single line of code
                </h3>
                <p className="mt-3 text-lg text-gray-500">
                  Coverdash's partner strategy is centered around a simplified
                  embedded solution. No need to spend months parsing through
                  complicated insurance API documentation. We have distilled our
                  entire offering into one singular line of code that gives
                  partners access to our insurance experience on your platform
                  including quote, rate, bind and checkout!
                </p>

                <dl className="mt-10 space-y-10">
                  {embedSteps.map((item) => (
                    <div key={item.title}>
                      <dt className="flex flex-col h-full justify-center">
                        <div className="absolute flex h-10 w-10 items-center justify-center rounded-md bg-primary text-white">
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <p className="ml-16 text-lg font-medium leading-6 text-alt">
                          {item.title}
                        </p>
                      </dt>
                    </div>
                  ))}
                </dl>
              </FadeIn>
            </div>

            <div className="col-span-3 mt-16 lg:mt-0 " aria-hidden="true">
              <FadeIn delay={0.2}>
                <WidgetExample />
              </FadeIn>
            </div>
          </div>

          <div className="mt-20 md:mt-32 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-12 lg:gap-8 px-4 md:px-0">
            {coverageItems.map((item, idx) => (
              <FadeIn delay={idx / 10} key={item.icon}>
                <div className="flow-root rounded-lg bg-gray-50 shadow-md px-6 pb-8 h-full text-center">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-white p-2 shadow-md border border-gray-100">
                        <Image
                          src={item.icon}
                          alt="icon"
                          aria-hidden="true"
                          width={60}
                          height={60}
                        />
                      </span>
                    </div>
                    <p className="mt-5 text-base text-gray-500">
                      {item.description}
                    </p>
                  </div>
                </div>
              </FadeIn>
            ))}
          </div>

          <div className="col-start-2 col-span-2 text-center mt-10">
            <Link legacyBehavior href="partner#contact-us">
              <a className="btn-primary">Become a partner</a>
            </Link>
          </div>
        </div>
      </div>

      <BottomGraphic />
      <Footer />
    </>
  )
}

export default Home
